export const UNDO_ICON = `<svg width="16px" height="14px" viewBox="0 0 16 14">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(0.000000, -1.000000)" fill="#293040">
        <path d="M11,5 C13.7614237,5 16,7.23857625 16,10 C16,12.7614237 13.7614237,15 11,15 L7,15 L7,14 L11,14
        C13.209139,14 15,12.209139 15,10 C15,7.790861 13.209139,6 11,6 L5,6 L5,10 L0,5.5 L5,1 L5,5 L11,5 Z"></path>
    </g>
</g>
</svg>`

export const REDO_ICON = `<svg width="16px" height="14px" viewBox="0 0 16 14">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(0.000000, -1.000000)" fill="#293040">
        <path d="M5,5 L11,5 L11,1 L16,5.5 L11,10 L11,6 L5,6 C2.790861,6 1,7.790861 1,10 C1,12.209139 2.790861,14 5,14 L9,14
        L9,15 L5,15 C2.23857625,15 0,12.7614237 0,10 C0,7.23857625 2.23857625,5 5,5 Z"></path>
    </g>
</g>
</svg>`

export const CLEAN_ICON = `<svg width="16px" height="15px" viewBox="0 0 16 15">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g fill="#293040">
        <path d="M4.32708124,14 L8.37150583,14 L10.5729409,11.5550586 L4.6297198,6.20375828 L1.25465311,9.95542661
        C1.09078634,10.1375782 1.0006437,10.3742369 1.00180556,10.6192474 C1.00295568,10.8617824 1.10621221,11.092615
        1.28624979,11.2551297 L4.32708124,14 Z M16,14 L16,15 L3.93760008,15 L0.597250175,11.9923354 C0.217072683,11.6500221
        1.59049582e-13,11.1091757 1.59428026e-13,10.6149383 C1.59806471e-13,10.1207008 0.183000942,9.64396706 0.513710349,9.27667705
        L8.56855175,0.330869394 L16,7.02217546 L9.71713856,14 L16,14 Z"></path>
    </g>
</g>
</svg>`

export const BOLD_ICON = `<svg width="12px" height="16px" viewBox="0 0 12 16">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(-2.000000, 0.000000)" fill="#293040" fill-rule="nonzero">
        <path d="M11.540636,7.0842147 C13.0068647,7.89340318 14,9.45459285 14,11.2477417 C14,13.8723415 11.8723415,16
        9.2477417,16 L2,16 L2,7.99258423 L2,-5.06261699e-14 L9.00370789,-4.75175455e-14 C11.2107991,-4.79229815e-14 13,1.78920092
        13,3.99629211 C13,5.24014846 12.4317244,6.35127692 11.540636,7.0842147 Z M3.5,1.5 L3.5,6.49258423 L9.00370789,6.49258423
        C10.382372,6.49258423 11.5,5.37495618 11.5,3.99629211 C11.5,2.61762805 10.382372,1.5 9.00370789,1.5 L3.5,1.5 Z M3.5,14.5
        L9.2477417,14.5 C11.0439144,14.5 12.5,13.0439144 12.5,11.2477417 C12.5,9.45156904 11.0439144,7.9954834 9.2477417,7.9954834
        L3.5,7.9954834 L3.5,14.5 Z"></path>
    </g>
</g>
</svg>`

export const ITALIC_ICON = `<svg width="9px" height="16px" viewBox="0 0 9 16">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(-4.000000, 0.000000)" fill="#293040">
        <path d="M10.2565501,1.5 L8.41332415,14.5 L12,14.5 L12,16 L4,16 L4,14.5 L6.90083611,14.5 L8.74406208,1.5 L5,1.5
        L5,0 L13,0 L13,1.5 L10.2565501,1.5 Z"></path>
    </g>
</g>
</svg>`

export const UNDERLINE_ICON = `<svg width="16px" height="16px" viewBox="0 0 16 16">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <polygon fill="#293040" points="2 14 14 14 14 16 2 16"></polygon>
    <path d="M4.5,3 L4.5,7 C4.5,8.93299662 6.06700338,10.5 8,10.5 C9.93299662,10.5 11.5,8.93299662 11.5,7 L11.5,1.5
    L11.4998322,0 L13,0 L13,7 C13,9.76142375 10.7614237,12 8,12 C5.23857625,12 3,9.76142375 3,7 L3,0 L4.49893188,0
    L4.49893188,3 L4.5,3 Z" fill="#293040" fill-rule="nonzero"></path>
</g>
</svg>`

export const STRIKE_ICON = `<svg width="14px" height="16px" viewBox="0 0 14 16">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(-1.000000, 0.000000)" fill="#293040">
        <path d="M9.93321102,9 L1,9 L1,7 L3.84197169,7 C3.31015092,6.28495098 3,5.42507332 3,4.5 C3,2.01471863
        5.23857625,0 8,0 C10.5736409,0 12.6931261,1.75002477 12.969484,4 L11.4507289,4 C11.1737591,2.60626201 9.76011738,1.5 8,1.5
        C6.03382604,1.5 4.5,2.88044344 4.5,4.5 C4.5,5.52604325 5.11562351,6.45611494 6.06678898,7 L15,7 L15,9 L12.1580283,9
        C12.6898491,9.71504902 13,10.5749267 13,11.5 C13,13.9852814 10.7614237,16 8,16 C5.42635908,16 3.30687393,14.2499752
        3.03051599,12 L4.54927112,12 C4.82624092,13.393738 6.23988262,14.5 8,14.5 C9.96617396,14.5 11.5,13.1195566 11.5,11.5
        C11.5,10.4739568 10.8843765,9.54388506 9.93321102,9 Z"></path>
    </g>
</g>
</svg>`

export const COLOR_ICON = `<svg width="16px" height="16px" viewBox="0 0 16 16">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <polygon fill="#293040" points="2 14 14 14 14 16 2 16"></polygon>
    <path d="M10.9166667,8.5 L5.08333333,8.5 L4.26618123,10.461165 L3.62338765,12 L2,12 L7,0 L9,0 L14,12 L12.3824972,12
    L11.5753038,10.0807292 L10.9166667,8.5 Z M10.2916667,7 L8,1.5 L5.70833333,7 L10.2916667,7 Z"
    fill="#293040" fill-rule="nonzero"></path>
</g>
</svg>`

export const BACKGROUND_COLOR_ICON = `  <svg width="16px" height="16px" viewBox="0 0 16 16">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path d="M4.64447006,9.66583547 L3.17597929,8.33216965 L6.15933183,5.11039074 L5.42508645,4.44355783
    L9.39179153,1.0658141e-13 L16,6.00149616 L12.0332949,10.445054 L11.2990495,9.77822108 L8.31569698,13 L6.84720621,11.6663342
    L5.69344476,12.9587937 L2.36400649,12.9587937 L2,12.6282074 L4.64447006,9.66583547 Z M4.57134219,8.25840959
    L8.24256911,11.5925741 L10.5648041,9.11138818 L6.89357722,5.77722364 L4.57134219,8.25840959 Z M14.6046371,6.07525623
    L9.4649194,1.40742588 L6.82044935,4.36979777 L11.960167,9.03762811 L14.6046371,6.07525623 Z M2,14 L14,14 L14,16 L2,16 L2,14 Z"
    fill="#293040"></path>
</g>
</svg>`

export const ALIGN_LEFT_ICON = `<svg width="16px" height="16px" viewBox="0 0 16 16">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path d="M0,0 L1.5,0 L1.5,16 L0,16 L0,0 Z M4,3 L13,3 L13,7 L4,7 L4,3 Z M5,4 L5,6 L12,6 L12,4 L5,4 Z M4,9 L16,9
    L16,13 L4,13 L4,9 Z M5,10 L5,12 L15,12 L15,10 L5,10 Z" fill="#293040"></path>
</g>
</svg>`

export const ALIGN_CENTER_ICON = `<svg width="16px" height="16px" viewBox="0 0 16 16">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path d="M8.75,7 L8.75,9 L16,9 L16,13 L8.75,13 L8.75,16 L7.25,16 L7.25,13 L0,13 L0,9 L7.25,9 L7.25,7 L2,7
    L2,3 L7.25,3 L7.25,0 L8.75,0 L8.75,3 L14,3 L14,7 L8.75,7 Z M1,10 L1,12 L15,12 L15,10 L1,10 Z M3,4 L3,6 L13,6
    L13,4 L3,4 Z" fill="#293040"></path>
</g>
</svg>`

export const ALIGN_RIGHT_ICON = `<svg width="16px" height="16px" viewBox="0 0 16 16">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path d="M14.5,0 L16,0 L16,16 L14.5,16 L14.5,0 Z M3,3 L12,3 L12,7 L3,7 L3,3 Z M4,4 L4,6 L11,6 L11,4 L4,4 Z
    M0,9 L12,9 L12,13 L0,13 L0,9 Z M1,10 L1,12 L11,12 L11,10 L1,10 Z" fill="#293040"></path>
</g>
</svg>`

export const LIST_UNORDERED_ICON = `<svg width="16px" height="16px" viewBox="0 0 16 16">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g fill="#293040">
        <path d="M5,1 L16,1 L16,2.5 L5,2.5 L5,1 Z M2,4 C0.8954305,4 0,3.1045695 0,2 C0,0.8954305 0.8954305,0 2,0
        C3.1045695,0 4,0.8954305 4,2 C4,3.1045695 3.1045695,4 2,4 Z M2,3 C2.55228475,3 3,2.55228475 3,2 C3,1.44771525
        2.55228475,1 2,1 C1.44771525,1 1,1.44771525 1,2 C1,2.55228475 1.44771525,3 2,3 Z M2,10 C0.8954305,10 0,9.1045695
        0,8 C0,6.8954305 0.8954305,6 2,6 C3.1045695,6 4,6.8954305 4,8 C4,9.1045695 3.1045695,10 2,10 Z M2,9 C2.55228475,9
        3,8.55228475 3,8 C3,7.44771525 2.55228475,7 2,7 C1.44771525,7 1,7.44771525 1,8 C1,8.55228475 1.44771525,9 2,9 Z
        M2,16 C0.8954305,16 0,15.1045695 0,14 C0,12.8954305 0.8954305,12 2,12 C3.1045695,12 4,12.8954305 4,14 C4,15.1045695
        3.1045695,16 2,16 Z M2,15 C2.55228475,15 3,14.5522847 3,14 C3,13.4477153 2.55228475,13 2,13 C1.44771525,13 1,13.4477153
        1,14 C1,14.5522847 1.44771525,15 2,15 Z M5,7 L16,7 L16,8.5 L5,8.5 L5,7 Z M5,13 L16,13 L16,14.5 L5,14.5 L5,13 Z"></path>
    </g>
</g>
</svg>`

export const LIST_ORDERED_ICON = `<svg width="16px" height="16px" viewBox="0 0 16 16">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g fill="#293040">
        <path d="M5,1 L16,1 L16,2.5 L5,2.5 L5,1 Z M0,0 L2,0 L2,3 L3,3 L3,4 L0,4 L0,3 L1,3 L1,1 L0,1 L0,0 Z M5,7 L16,7
        L16,8.5 L5,8.5 L5,7 Z M5,13 L16,13 L16,14.5 L5,14.5 L5,13 Z M0,15 L1.75,15 C1.88807119,15 2,14.8880712 2,14.75
        C2,14.6119288 1.88807119,14.5 1.75,14.5 L0,14.5 L0,13.5 L1.75,13.5 C1.88807119,13.5 2,13.3880712 2,13.25 C2,13.1119288
        1.88807119,13 1.75,13 L0,13 L0,12 L1.5,12 C2.32842712,12 3,12.6715729 3,13.5 C3,13.6753177 2.96992289,13.8436105
        2.91464715,14 C2.96992289,14.1563895 3,14.3246823 3,14.5 C3,15.3284271 2.32842712,16 1.5,16 L0,16 L0,15 Z M2,7.5
        C2,7.35127258 1.9375,7.22627258 1.84375,7.13845444 C1.76307119,7.05596441 1.63807119,7 1.5,7 C1.22385763,7 1,7.22385763
        1,7.5 L1.62630326e-19,7.5 C0.147399902,6.5 0.647399902,6 1.5,6 C2.32842712,6 3,6.67157288 3,7.5 C3,8.07312512
        2.50016007,8.55650475 2.22821045,8.74661255 C1.95626083,8.93672035 1.77325955,8.99789088 1.76769962,9 L3,9 L3,10
        L0,10 L1.62630326e-19,9 C0.619515577,8.77257137 1.06188822,8.55939591 1.32711792,8.36047363 C1.6371448,8.12795347
        1.87259637,7.91592701 1.96143498,7.69289356 C1.98627694,7.633537 2,7.5683711 2,7.5 Z"></path>
    </g>
</g>
</svg>`

export const LIST_CHECK_ICON = `<svg width="15px" height="14px" viewBox="0 0 15 14">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(-1058.000000, -97.000000)">
        <g transform="translate(407.000000, 93.000000)">
            <g transform="translate(650.000000, 2.000000)">
                <rect x="0" y="0" width="16" height="16"></rect>
                <polygon fill="#293040" fill-rule="nonzero" points="14 15 14 9.37100497 15 9.37100497 15
                16 1 16 1 2 10.1946467 2 10.1946467 3 2 3 2 15"></polygon>
                <path d="M14.4525049,2.48740937 C14.7356009,2.1850362 15.8305911,3.21021745 15.5474951,3.51259063
                L8.45990379,11.0828057 L4.46558382,7.02621226 C4.17496481,6.73106236 4.17863783,6.25620283 4.47378774,5.96558382
                C4.76893764,5.67496481 5.24379717,5.67863783 5.53441618,5.97378774 L8.43263799,8.91719429 L14.4525049,2.48740937 Z"
                fill="#293040" fill-rule="nonzero"></path>
            </g>
        </g>
    </g>
</g>
</svg>`

export const CODE_BLOCK_ICON = `<svg t="1565073788159" class="icon" viewBox="0 0 1024 1024" width="16" height="16">
<path d="M110.78 469.52l70.44-39.24A60 60 0 0 0 212 377.9L212 212a120 120 0 0 1 120-120l60 0a30
30 0 0 1 0 60L332 152a60 60 0 0 0-59.99999999 60l0 175.38a120 120 0 0
1-63.48000001 105.9L173.42 512l35.1 18.72A120 120 0 0 1 272 636.56L272.00000001 812a60 60 0 0 0 59.99999999 60l60 0a30 30
0 1 1 0 60L332 932a120 120 0 0 1-120-120l0-165.9a60
60 0 0 0-30.78-52.38l-70.44-39.24a45 45 0 0 1 0-84.96z m742.44 1e-8a45 45 0 0 1 0 84.95999998l-70.44 39.24000001a60
60 0 0 0-30.78000001 52.38L751.99999999 812a120 120 0 0 1-119.99999999 120L572 932a30 30
0 1 1 0-60l60 0a60 60 0 0 0 60-60l0-175.38a120 120
0 0 1 63.48-105.9L790.58 512l-35.1-18.72A120 120 0 0 1 692 387.44L692 212a60 60 0 0 0-60-60L572 152a30
30 0 0 1 0-60l60 0a120 120 0 0 1 119.99999999 120l0 165.9a60 60 0 0 0 30.78000001 52.38l70.44 39.24000001z"></path></svg>`

export const BLOCKQUOTE_ICON = `<svg width="16px" height="16px" viewBox="0 0 16 16">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(0.000000, 1.000000)" fill="#000000" fill-rule="nonzero">
        <path d="M6.86733556,12.5574136 L6.86733556,7.99108138 L2.52931996,7.99108138 C2.41516165,5.02296544
        3.10011148,3.42474916 5.72575251,2.0548495 L5.72575251,0 C1.84437012,1.36989967 -0.0963210702,3.88138239 0.0178372352,7.76276477
        L0.0178372352,12.5574136 L6.86733556,12.5574136 Z M16,12.5574136 L16,7.99108138 L11.6619844,7.99108138 C11.5478261,5.02296544
        12.2327759,3.42474916 14.8584169,2.0548495 L14.8584169,0 C10.9770346,1.36989967 9.03634337,3.88138239 9.15050167,7.76276477
        L9.15050167,12.5574136 L16,12.5574136 Z"></path>
    </g>
</g>
</svg>`

export const IMAGE_ICON = `<svg width="16px" height="16px" viewBox="0 0 16 16">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(-1196.000000, -96.000000)" fill="#293040" fill-rule="nonzero">
        <g transform="translate(407.000000, 93.000000)">
            <path d="M804,4 L790,4 L790,11.940983 L794.058237,9.91186456 L798.536534,13.3949845 L801.014076,11.908459
            L804,13.8990748 L804,4 Z M803.72265,14.9160251 L800.985924,13.091541 L798.463466,14.6050155 L793.941763,11.0881354
            L790.223607,12.9472136 C790.151699,12.9831673 790.07527,13.000168 790,13.0001094 L790,18 L804,18 L804,15.0000529
            C803.90455,15.0001406 803.80803,14.9729453 803.72265,14.9160251 Z M789,19 L789,3 L805,3 L805,19 L789,19 Z M801,9
            C800.447715,9 800,8.55228475 800,8 C800,7.44771525 800.447715,7 801,7 C801.552285,7 802,7.44771525 802,8 C802,8.55228475
            801.552285,9 801,9 Z"></path>
        </g>
    </g>
</g>
</svg>`

export const FILE_ICON = `<svg width="16px" height="16px" viewBox="0 0 16 16" >
<g id="icon/01" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path d="M16,16 L1,16 L1,0 L12,0 L16,4 L16,16 Z M11,1 L2,1 L2,15 L15,15 L15,
    5 L11,5 L11,1 Z M12,1.533 L12,4 L14.52,4 L12,1.533 Z"
    id="Combined-Shape" fill="#293040" fill-rule="nonzero">
    </path>
    <polygon id="path" fill="#293040" points="8 8 6 8 8.5 5 11 8 9 8 9 12 8 12"></polygon>
</g>
</svg>`

export const TABLE_ICON = `<svg width="16px" height="16px" viewBox="0 0 16 16">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(-1227.000000, -96.000000)" fill="#293040">
        <g transform="translate(407.000000, 93.000000)">
            <path d="M820,3 L836,3 L836,19 L820,19 L820,3 Z M821,4 L821,8 L835,8 L835,4 L821,4 Z M821,9 L821,13 L825,
            13 L825,9 L821,9 Z M821,14 L821,18 L825,18 L825,14 L821,14 Z M826,9 L826,13 L830,13 L830,9 L826,9 Z M826,
            14 L826,18 L830,18 L830,14 L826,14 Z M831,9 L831,13 L835,13 L835,9 L831,9 Z M831,14 L831,18 L835,18 L835,
            14 L831,14 Z"></path>
        </g>
    </g>
</g>
</svg>`

export const LINK_ICON = `<svg width="16px" height="16px" viewBox="0 0 16 16">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(-1258.000000, -96.000000)" fill="#293040" fill-rule="nonzero">
        <g transform="translate(407.000000, 93.000000)">
            <path d="M855.499,13.863 L861.863,7.499 L862.573,8.209 L856.209,14.573 L855.499,13.863 Z M854.646498,9.99452063
            C854.661947,10.0108615 854.677673,10.0270363 854.693676,10.0430391 L855.388521,10.7378841 L852.802248,13.3241571
            C851.732584,14.3938208 851.732584,16.1280884 852.802248,17.1977522 C853.871912,18.2674159 855.606179,18.2674159
            856.675843,17.1977522 L859.262116,14.6114792 L859.956961,15.3063242 C859.972964,15.3223269 859.989138,15.3380528
            860.005479,15.3535018 L857.468863,17.8901187 C855.989021,19.3699604 853.589723,19.3699604 852.109881,17.8901187
            C850.63004,16.4102769 850.63004,14.0109793 852.109881,12.5311375 L854.646498,9.99452063 Z M857.994521,6.64649819
            L860.531137,4.10988134 C862.010979,2.63003955 864.410277,2.63003955 865.890119,4.10988134 C867.36996,5.58972313
            867.36996,7.98902073 865.890119,9.46886252 L863.353502,12.0054794 C863.338053,11.9891385 863.322327,11.9729637
            863.306324,11.9569609 L862.611479,11.2621159 L865.197752,8.67584293 C866.267416,7.60617918 866.267416,5.87191157
            865.197752,4.80224782 C864.128088,3.73258406 862.393821,3.73258406 861.324157,4.80224782 L858.737884,7.38852082
            L858.043039,6.69367584 C858.027036,6.67767307 858.010862,6.66194719 857.994521,6.64649819 Z"></path>
        </g>
    </g>
</g>
</svg>`

export const EMOJI_ICON = `<svg width="16px" height="16px" viewBox="0 0 16 16">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path d="M8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278
    12.418278,16 8,16 Z M8,15 C11.8659932,15 15,11.8659932 15,8 C15,4.13400675 11.8659932,1 8,1 C4.13400675,1 1,4.13400675
    1,8 C1,11.8659932 4.13400675,15 8,15 Z M5,7 C4.44771525,7 4,6.55228475 4,6 C4,5.44771525 4.44771525,5 5,5 C5.55228475,5
    6,5.44771525 6,6 C6,6.55228475 5.55228475,7 5,7 Z M11,7 C10.4477153,7 10,6.55228475 10,6 C10,5.44771525 10.4477153,5 11,5
    C11.5522847,5 12,5.44771525 12,6 C12,6.55228475 11.5522847,7 11,7 Z M4,10 L12,10 C11.5415129,11.7252272 9.9244686,13 8,13
    C6.0755314,13 4.45848714,11.7252272 4,10 Z" fill="#293040"></path>
</g>
</svg>`

export const SCREENSHOT_ICON = `<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g transform="translate(-1529.000000, -96.000000)">
        <g transform="translate(1529.000000, 96.000000)">
            <rect fill="#000000" fill-rule="nonzero" opacity="0" x="0" y="0" width="16" height="16"></rect>
            <polyline stroke="currentColor" points="16 14 2 14 2 0 2 0"></polyline>
            <polyline stroke="currentColor" points="4 2 14 2 14 12"></polyline>
            <rect fill="currentColor" x="0" y="1.5" width="2" height="1"></rect>
            <rect fill="currentColor"
              transform="translate(14.000000, 15.000000)
              rotate(-90.000000)
              translate(-14.000000, -15.000000) " x="13" y="14.5" width="2" height="1">
            </rect>
        </g>
    </g>
</g>
</svg>`

export const CODE_ICON = `<svg t="1721956397010" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4254" width="20" height="20"><path d="M438.4 849.1l222.7-646.7c0.2-0.5 0.3-1.1 0.4-1.6L438.4 849.1z" opacity=".224" p-id="4255"></path><path d="M661.2 168.7h-67.5c-3.4 0-6.5 2.2-7.6 5.4L354.7 846c-0.3 0.8-0.4 1.7-0.4 2.6 0 4.4 3.6 8 8 8h67.8c3.4 0 6.5-2.2 7.6-5.4l0.7-2.1 223.1-648.3 7.4-21.4c0.3-0.8 0.4-1.7 0.4-2.6-0.1-4.5-3.6-8.1-8.1-8.1zM954.6 502.1c-0.8-1-1.7-1.9-2.7-2.7l-219-171.3c-3.5-2.7-8.5-2.1-11.2 1.4-1.1 1.4-1.7 3.1-1.7 4.9v81.3c0 2.5 1.1 4.8 3.1 6.3l115 90-115 90c-1.9 1.5-3.1 3.8-3.1 6.3v81.3c0 4.4 3.6 8 8 8 1.8 0 3.5-0.6 4.9-1.7l219-171.3c6.9-5.4 8.2-15.5 2.7-22.5zM291.1 328.1l-219 171.3c-1 0.8-1.9 1.7-2.7 2.7-5.4 7-4.2 17 2.7 22.5l219 171.3c1.4 1.1 3.1 1.7 4.9 1.7 4.4 0 8-3.6 8-8v-81.3c0-2.5-1.1-4.8-3.1-6.3l-115-90 115-90c1.9-1.5 3.1-3.8 3.1-6.3v-81.3c0-1.8-0.6-3.5-1.7-4.9-2.7-3.5-7.7-4.1-11.2-1.4z" p-id="4256"></path></svg>`

export const MENTION_ICON = `<i class="icon-set-keyword"></i>`

export const QUICK_MENU_ICON = `<i class="icon-publish"></i>`

export const SAVE_ICON = `<i class="icon-save"></i>`

export const HELP_ICON = `<svg t="1721963829631" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9764" width="20" height="20"><path d="M512 74.666667C270.933333 74.666667 74.666667 270.933333 74.666667 512S270.933333 949.333333 512 949.333333 949.333333 753.066667 949.333333 512 753.066667 74.666667 512 74.666667z m0 810.666666c-204.8 0-373.333333-168.533333-373.333333-373.333333S307.2 138.666667 512 138.666667 885.333333 307.2 885.333333 512 716.8 885.333333 512 885.333333z" fill="#000000" p-id="9765"></path><path d="M512 746.666667m-42.666667 0a42.666667 42.666667 0 1 0 85.333334 0 42.666667 42.666667 0 1 0-85.333334 0Z" fill="#000000" p-id="9766"></path><path d="M512 245.333333c-76.8 0-138.666667 61.866667-138.666667 138.666667 0 17.066667 14.933333 32 32 32s32-14.933333 32-32c0-40.533333 34.133333-74.666667 74.666667-74.666667s74.666667 34.133333 74.666667 74.666667c0 27.733333-53.333333 76.8-91.733334 100.266667-8.533333 6.4-14.933333 17.066667-14.933333 27.733333v106.666667c0 17.066667 14.933333 32 32 32s32-14.933333 32-32v-89.6c34.133333-25.6 106.666667-83.2 106.666667-145.066667 0-76.8-61.866667-138.666667-138.666667-138.666667z" fill="#000000" p-id="9767"></path></svg>`

export const SCROLL_TABLE_ICON = `<i class="icon-switch"></i>`

export const GLOBAL_LINK_ICON = `<svg t="1721963743677" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8628" width="20" height="20"><path d="M713.130667 283.221333V228.352a9.159111 9.159111 0 0 0-9.130667-9.130667H265.102222a9.159111 9.159111 0 0 0-9.102222 9.130667v54.869333c0 5.034667 4.096 9.130667 9.130667 9.130667H704a9.159111 9.159111 0 0 0 9.130667-9.130667z m-448 100.579556a9.130667 9.130667 0 0 0-9.130667 9.130667v54.869333c0 5.006222 4.096 9.130667 9.130667 9.130667h210.289777a9.159111 9.159111 0 0 0 9.159112-9.130667v-54.869333a9.159111 9.159111 0 0 0-9.159112-9.130667H265.130667z m164.579555 516.551111H164.579556V95.800889h640v279.352889c0 5.034667 4.096 9.159111 9.130666 9.159111h64a9.159111 9.159111 0 0 0 9.130667-9.159111V50.090667c0-20.252444-16.327111-36.579556-36.551111-36.579556H118.840889c-20.195556 0-36.551111 16.327111-36.551111 36.579556v896c0 20.195556 16.327111 36.551111 36.551111 36.551111h310.869333a9.159111 9.159111 0 0 0 9.130667-9.130667v-64a9.159111 9.159111 0 0 0-9.130667-9.159111z" fill="#000000" fill-opacity=".85" p-id="8629"></path><path d="M837.859556 656.298667l-107.861334 186.794666-68.977778-39.822222 107.861334-186.794667 68.977778 39.822223z" fill="#000000" p-id="8630"></path><path d="M691.114667 514.844444a159.146667 159.146667 0 0 1 217.315555-58.225777 159.061333 159.061333 0 0 1 58.197334 217.315555l-55.637334 96.369778-68.977778-39.822222 55.637334-96.369778a79.416889 79.416889 0 1 0-137.557334-79.416889l-55.637333 96.369778-68.977778-39.822222 55.637334-96.369778V514.844444z m-28.558223 208.782223l-56.462222 97.792a79.416889 79.416889 0 0 0 137.557334 79.388444l56.462222-97.763555 68.977778 39.822222-56.462223 97.763555a159.061333 159.061333 0 1 1-275.484444-159.061333l56.433778-97.763556 68.977777 39.822223z" fill="#000000" p-id="8631"></path></svg>`

export const FULLSCREEN_ICON = `<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="100%" height="100%" viewBox="0 0 1024 1024" version="1.1">
    <path fill="#333333" d="M64 384h96V160h224.2V64H64zM639.8 64v96H864v224h96V64zM864 864H639.8v96H960V639.61h-96zM160 639.61H64V960h320.2v-96H160z"/>
</svg>`

export const TRIANGLE_DOWN_ICON = `<i class="icon-triangle-down"></i>`

export const FORMAT_PAINTER_ICON = `<svg viewBox="0 0 32 32"><path fill="currentColor" d="M28.83 23.17L23 17.33V13a1 1 0 0 0-.29-.71l-10-10a1 1 0 0 0-1.42 0l-9 9a1 1 0 0 0 0 1.42l10 10A1 1 0 0 0 13 23h4.34l5.83 5.84a4 4 0 0 0 5.66-5.66ZM6 10.41l2.29 2.3l1.42-1.42L7.41 9L9 7.41l4.29 4.3l1.42-1.42L10.41 6L12 4.41L18.59 11L11 18.59L4.41 12Zm21.41 17a2 2 0 0 1-2.82 0l-6.13-6.12a1.8 1.8 0 0 0-.71-.29h-4.34l-1-1L20 12.41l1 1v4.34a1 1 0 0 0 .29.7l6.12 6.14a2 2 0 0 1 0 2.82"/></svg>`
